import React from "react";
import "./App.scss";
import Home from "./containers/Home";
import {Route, Switch} from 'react-router-dom';


const Main = () => (
    <Switch>
        <Route exact path='/' component={Home}></Route>
    </Switch>
);


function App() {
    return (
        <div>
            <Main/>
        </div>
    );
}

export default App;
