import React, { useContext } from "react";
import "./Footer.scss";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";

export default function Footer() {
  const { isDark } = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {emoji("Jaguar000212, aka Shryansh Parashar ❤️")}
        </p>
        <p className={isDark ? "dark-mode footer-text email" : "footer-text email"}>
          {emoji("E-Mail ✉️ : ")} {" "}
          <a href="mailto://jaguar000212@gmail.com">
            jaguar000212@gmail.com
          </a>
        </p>
        <p className={isDark ? "dark-mode footer-quote" : "footer-quote"}>
          {"😮‍💨 A long drive paired with soulful music can dissolve the toughest challenges."}
        </p>
        <p className={isDark ? "dark-mode footer-disclaimer" : "footer-disclaimer"}>
          *Assets used belong to their respective owners.
        </p>
      </div>
    </Fade>
  );
}