import React from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";

export default function SoftwareSkill() {
    return (<div>
        <div className="software-skills-main-div">
            <div className="software-text-div languages">
                <p className="dark-mode head subTitle">Languages</p>
                <ul className="dev-icons">
                    {skillsSection.Languages.map((skills, i) => {
                        return (<li
                            key={i}
                            className="software-skill-inline"
                            name={skills.skillName}
                        >
                            <i className={skills.fontAwesomeClassname}></i>
                            <p>{skills.skillName}</p>
                        </li>);
                    })}
                </ul>
            </div>
            <div className="software-text-div frontend">
                <p className="dark-mode head subTitle">Frontend Technologies</p>
                <ul className="dev-icons">
                    {skillsSection.frontendTech.map((skills, i) => {
                        return (<li
                            key={i}
                            className="software-skill-inline"
                            name={skills.skillName}
                        >
                            <i className={skills.fontAwesomeClassname}></i>
                            <p>{skills.skillName}</p>
                        </li>);
                    })}
                </ul>
            </div>
            <div className="software-text-div backend">
                <p className="dark-mode head subTitle">Backend Technologies</p>
                <ul className="dev-icons">
                    {skillsSection.backendTech.map((skills, i) => {
                        return (<li
                            key={i}
                            className="software-skill-inline"
                            name={skills.skillName}
                        >
                            <i className={skills.fontAwesomeClassname}></i>
                            <p>{skills.skillName}</p>
                        </li>);
                    })}
                </ul>
            </div>
            <div className="software-text-div tools">
                <p className="dark-mode head subTitle">Tools</p>
                <ul className="dev-icons">
                    {skillsSection.Tools.map((skills, i) => {
                        return (<li
                            key={i}
                            className="software-skill-inline"
                            name={skills.skillName}
                        >
                            <i className={skills.fontAwesomeClassname}></i>
                            <p>{skills.skillName}</p>
                        </li>);
                    })}
                </ul>
            </div>
        </div>
    </div>);
}
